import React, { useCallback, useState, useContext } from 'react'
import { signOut, putDetails } from './Services/User-Service'
import Logo from "./assets/logos/new-logo.png"
import FullLogo from "./assets/logos/new-full-logo.png"
import { useAuth } from './Context/AuthContext'
import { useEffect } from 'react';
import { Drawer } from './App/Components/Drawer'
import { NavigationItems } from './App/NavigationItems';
import SettingsModal from './App/Modals/SettingsModal'
import { UserContext } from './Context/UserContext'

export const initialNavigationItems = [
  {
    name: 'Início',
    route: '/inicio',
    icon: <i className="fa fa-home" id="inicio"></i>,
    id: 'inicio',
  },
  {
    name: 'Dashboard',
    route: '/dashboard-financeiro',
    icon: <i className="fa fa-pie-chart" id="dashboard"></i>,
    id: 'dashboard',
    permissions: ['financial_dashboard'],
  },
  {
    name: 'Chats',
    route: '/chat',
    icon: <i className="fa fa-comments" id="chats"></i>,
    id: 'chats',
    permissions: ['manage_chat'],
  },
  {
    name: 'Dashboard Atendimentos',
    route: '/dashboard-atendimentos',
    icon: <i className="fa fa-line-chart" id="dashboardAttendance"></i>,
    id: 'dashboardAttendance',
    permissions: ['dashboard_attendance']
  },
  {
    name: 'Relatório atendimentos',
    route: '/relatorio-atendimentos',
    icon: <i className="fa fa-file-text-o" id="relatorioAtendimentos"></i>,
    id: 'relatorioAtendimentos',
    permissions: ['report_attendance']
  },
  {
    name: 'Contatos',
    route: '/contatos',
    icon: <i className="fa fa-address-book" id="contatos"></i>,
    id: 'contatos',
    permissions: ['manage_contact'],
  },
  {
    name: 'Canais',
    route: '/canais',
    icon: <i className="fa fa-list-alt" id="canais"></i>,
    id: 'canais',
    permissions: ['manage_channel'],
  },
  {
    name: 'Departamentos',
    route: '/departamentos',
    icon: <i className="fa fa-building" id="departamentos"></i>,
    id: 'departamentos',
    permissions: ['manage_department']
  },
  {
    name: 'Grupo contatos',
    route: '/grupo-contatos',
    icon: <i className="fa fa-users" id="grupoContatos"></i>,
    id: 'grupoContatos',
    permissions: ['manage_contact_group']
  },
  {
    name: 'Campanhas',
    route: '/campanhas',
    icon: <i className="fa fa-bullhorn" id="campanhas"></i>,
    id: 'campanhas',
    permissions: ['manage_campaign'],
  },
  {
    name: 'Dashboard Campanhas',
    route: '/dashboard-campanhas',
    icon: <i className="fa fa-bar-chart" id="campanhasDashboard"></i>,
    id: "campanhasDashboard",
    permissions: ['dashboard_campaign'],
  },
  {
    name: 'Usuarios',
    route: '/usuarios',
    icon: <i className="fa fa-user" id="usuarios"></i>,
    id: 'usuarios',
    permissions: ['manage_user']
  },
  {
    name: 'Tags',
    route: '/tags',
    icon: <i className="fa fa-tags" id="tags"></i>,
    id: 'tags',
    permissions: ['manage_tag']
  }
];

function Navigation() {
  const decodedToken = useAuth()
  const [navigationItems, setNavigationItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [openModalSettings, setModalSettings] = useState({ open: false, info: null });

  const {
    settings,
    setSettings,
    getLoggedDetailsSettings
  } = useContext(UserContext);

  const handleOpen = useCallback(() => {
    document.getElementById('navigation-drawer').classList.remove('navigation-desktop')
    setOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    document.getElementById('navigation-drawer').classList.add('navigation-desktop')
    setOpen(false)
  }, []);

  const getInitials = (name) => {
    const words = name.split(' ');
    let initials = '';

    if (words.length >= 1) {
      initials += words[0][0];
    }

    if (words.length >= 2) {
      initials += words[words.length - 1][0];
    }

    initials = initials.toUpperCase();

    return initials;
  }

  const getFirstAndLastname = (name) => {
    const words = name.split(' ');

    const firstname = words[0]
    let lastname = ''
    if (words.length > 1) {
      lastname = words[words.length - 1]
    }

    return `${firstname} ${lastname}`
  }

  const handleSave = async (payload, notifications) => {
    const emailConfig = {
      inAttendanceMail: false,
      waitingAttendanceMail: false
    }
    let payloadFormated = { ...payload, ...emailConfig }
    try {
      await putDetails(payloadFormated)
      setSettings(notifications)
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    getLoggedDetailsSettings()
    const filteredItems = initialNavigationItems.filter(item => {
      if (item.permissions) {
        if (!decodedToken.permissions) return false

        return item.permissions.some(permission => decodedToken.permissions.includes(permission))
      }

      return true
    });

    setNavigationItems(filteredItems)
  }, []);

  return (
    <>
      <nav className="navigation-mobile navigation h-100">
        <div className={open ? 'nav-group nav-group-opened h-100 d-flex flex-column' : 'nav-group nav-group-closed h-100 d-flex flex-column'}>
          <p href="/#/" className="logo" onClick={open ? handleClose : handleOpen}>
            <img src={!open ? Logo : FullLogo} alt="Logo" width={!open ? 50 : 100} />
            {!open ? <i className="fa fa-bars icon-toggle-drawer" aria-hidden="true"></i>
              : <i className="fa fa-times icon-toggle-drawer" aria-hidden="true"></i>}
          </p>
        </div>
      </nav>
      <Drawer anchor="left" open={open} onClose={handleClose} extendable>
        <nav className="navigation navigation-desktop h-100" id="navigation-drawer">
          <div className={open ? 'nav-group nav-group-opened h-100 d-flex flex-column' : 'nav-group nav-group-closed h-100 d-flex flex-column'}>
            <p href="/#/" className="logo" onClick={open ? handleClose : handleOpen}>
              <img src={!open ? Logo : FullLogo} alt="Logo" width={!open ? 50 : 100} />
              {!open ? <i className="fa fa-bars icon-toggle-drawer" aria-hidden="true"></i>
                : <i className="fa fa-times icon-toggle-drawer" aria-hidden="true"></i>}
            </p>
            <ul className='align-items-center scroll-items'>
              {navigationItems.map((item, i) => <NavigationItems key={i} item={item} tooltipName={"Tooltip-" + i} drawerOpen={open} setDrawerOpen={handleClose} />)}
            </ul>
            {navigationItems.length ?
              <>
                <div className='mt-auto'>
                  <div className="font-weight-bold rounded-lg mx-2 py-3 text-center mt-2 mx-auto" style={{ backgroundColor: '#e6e6e6', maxWidth: 50 }}>
                    {getInitials(decodedToken.name)}
                  </div>
                  <p className="font-weight-bold mx-2 mb-0 overflow-hidden" style={{ fontSize: 12, lineBreak: 'anywhere' }}>
                    {getFirstAndLastname(decodedToken.name)}
                  </p>
                  <div className='font-weight-bold overflow-hidden'
                    onClick={() => setModalSettings({ open: true, info: settings })}
                    style={{ cursor: 'pointer' }}
                    title="Configurações"
                  >
                    <p className='p-0 my-0'>
                      <i className="ti ti-settings " style={{ fontSize: 25 }} />
                    </p>
                  </div>
                  <a href="/#/" onClick={() => signOut()} title='Sair'>
                    <i className={open ? "ti ti-power-off mr-2" : "ti ti-power-off"}></i>
                    {open ? 'Sair' : ''}
                  </a>
                </div>
              </>
              : null}
            {openModalSettings.open && (
              <SettingsModal
                openModalSettings={openModalSettings}
                setModalSettings={setModalSettings}
                actionFunction={handleSave}
              />
            )}
          </div>
        </nav>
      </Drawer>
    </>
  )
}

export default Navigation
