import React from "react";
import { Modal, ModalBody, Label, Input } from 'reactstrap';
import DefaultImage from '../../assets/img/no-image.png'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import { useMobile } from "../Partials/useMobile/useMobile";
import PerfectScrollbar from "react-perfect-scrollbar";

const DetailsModal = ({
    openModalDetails,
    setOpenModalDetails

}) => {
    const data = openModalDetails?.info
    const isMobile = useMobile();

    const findTranslate = (data) => {
        switch (data) {
            case "started":
                return "Iniciado"

            case "transfer":
                return "Transferido"

            case "finished":
                return "Finalizado"

            default:
                break;
        }
    }

    const applyModalStyles = () => {
        const modalElement = document.querySelector(".modal-open .modal");
        if (modalElement) {
            modalElement.style.overflowX = "hidden";
            modalElement.style.overflowY = "hidden";
        }
    };

    return (
        <>
            <Modal isOpen={openModalDetails?.open}
                toggle={() => setOpenModalDetails(null)}
                zIndex={990}
                centered
                className="call modal-dialog-zoom" 
                size='lg'
                onOpened={() => applyModalStyles()}
            >
                <button className={"btn btn-light btn-modal-close"}
                    onClick={() => setOpenModalDetails(null)}>
                    <i className="fa fa-times"></i>
                </button>
                <ModalBody style={{ backgroundColor: "#e6e6e6",  padding: '20px 0'  }}>
                    <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial', overflow: 'hidden' }} />
                    <div className="mt-1" style={{ padding: '0 30px' }} >
                        <PerfectScrollbar options={{
                            suppressScrollX: true, 
                        }}
                            style={{
                                maxHeight: '80vh',
                                overflowX: 'hidden',
                            }}>
                            <h4 style={{ color: 'black' }}>{"Detalhes do atendimento"}</h4>
                            <div className="transfer-info-box">
                                <img src={data?.customer?.image || DefaultImage}
                                    alt="Imagem"
                                    className="rounded-circle mr-2" style={{ width: 100, height: 100 }} />
                                <div>
                                    <strong
                                        style={{
                                            fontSize: '18px',
                                            width: '110px',
                                            alignContent: 'space-evenly',
                                            color: 'black'
                                        }}>{data?.customer?.name}</strong><br />
                                </div>
                                <hr style={{ width: '100%', height: 10, borderColor: 'lightgray', marginBottom: 10 }} />
                                <div style={{ padding: '0 30px', color: 'black', fontWeight: 'bold', fontSize: '14px' }}>
                                    <div className='d-flex justify-content-between text-left pt-2'>
                                        <div className="w-100 text-left ml-2">
                                            {
                                                isMobile ?
                                                    (
                                                        <Label for="name" className='mt-4'>Atendente:</Label>
                                                    ) : (
                                                        <Label for="name" className='mt-4'>Nome do atendente:</Label>
                                                    )
                                            }

                                            <Input type="text"
                                                name="name"
                                                id="name"
                                                style={{ maxHeight: 200 }}
                                                readOnly
                                                autoComplete='off'
                                                value={data?.operator?.name} />
                                        </div>
                                        <div className="w-100 text-left ml-2">
                                            <Label for="status" className='mt-4'>Status:</Label>
                                            <Input type="text"
                                                name="status"
                                                id="status"
                                                style={{ maxHeight: 200 }}
                                                readOnly
                                                autoComplete='off'
                                                value={findTranslate(data?.status)} />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between text-left'>
                                        <div className="w-100 text-left ml-2">
                                            <Label for="name" className='mt-4'>Departamento:</Label>
                                            <Input type="text"
                                                name="department"
                                                id="department"
                                                style={{ maxHeight: 200 }}
                                                readOnly
                                                autoComplete='off'
                                                value={data?.department?.name} />
                                        </div>
                                        <div className="w-100 text-left ml-2">
                                            {
                                                isMobile ?
                                                    (
                                                        <Label for="name" className='mt-4'>Contato:</Label>
                                                    ) : (
                                                        <Label for="phone" className='mt-4'>Telefone do contato:</Label>
                                                    )
                                            }

                                            <Input type="text"
                                                name="name"
                                                id="name"
                                                style={{ maxHeight: 200 }}
                                                readOnly
                                                autoComplete='off'
                                                value={data?.customer?.cellPhone} />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center text-left  pt-3'
                                        style={{ position: 'relative' }}>
                                        <Timeline style={{
                                            height: '300px',
                                            width: '100%',
                                            fontSize: '14px',
                                            height: 'auto',
                                            position: 'relative'
                                        }} >
                                            {data?.logs?.map((event, index) => {
                                                const title = event.status === 'started'
                                                    ? `${event.operatorName} iniciou o atendimento`
                                                    : event.status === 'transfer'
                                                        ? `${event.operatorName || ''} Transferiu o atendimento`
                                                        : event.status === 'finished'
                                                            ? `${event.operatorName || ''} finalizou o atendimento`
                                                            : 'Evento desconhecido';
                                                const date = new Date(event.createdAt).toLocaleString();
                                                const icon = event.status === 'started'
                                                    ? <i className="fa fa-play " />
                                                    : event.status === 'transfer'
                                                        ? <i className="fa fa-exchange" />
                                                        : event.status === 'finished'
                                                            ? <i className="fa fa-check" />
                                                            : <i className="fa fa-question" />
                                                return (
                                                    <TimelineEvent
                                                        key={index}
                                                        title={title}
                                                        createdAt={date}
                                                        icon={icon}
                                                    >
                                                        {event.status === 'transfer'
                                                            ? <>O atendimento foi transferido para o departamento <span style={{ color: 'black', fontWeight: 'bold' }}>{event.departmentName}</span>. </>
                                                            : event.status === 'started'
                                                                ? (
                                                                    <>
                                                                        O operador iniciou o atendimento no departamento <span style={{ color: 'black', fontWeight: 'bold' }}>{event.departmentName}</span>.<br />
                                                                        {data?.protocol && (
                                                                            <span style={{ fontStyle: "italic", fontSize: '13px' }}>Protocolo: {data.protocol}</span>
                                                                        )}
                                                                    </>
                                                                )
                                                                : event.status === 'finished'
                                                                    ?
                                                                    (
                                                                        <>
                                                                            O atendimento foi finalizado.<br />
                                                                            {data?.reason && (
                                                                                <span style={{ fontStyle: "italic", fontSize: '13px' }}>
                                                                                    Motivo: {data.reason.typeName} <br /> Descrição: {data.reason.description}
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    )

                                                                    : 'Descrição do evento não disponível.'
                                                        }

                                                    </TimelineEvent>
                                                );
                                            })}
                                        </Timeline>

                                    </div>
                                </div>

                            </div>
                        </PerfectScrollbar>
                    </div>
                </ModalBody>
            </Modal >
        </>
    )

}

export default DetailsModal