import React, { useContext, useMemo, useState, useRef, useCallback, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useDispatch, useSelector } from "react-redux"
import { AttendanceContext } from '../../../Context/AttendanceContext'
import { getInAttendance } from '../../../Services/Attendance-Service'
import { mobileSidebarAction } from "../../../Store/Actions/mobileSidebarAction"
import { selectedChatAction } from "../../../Store/Actions/selectedChatAction"
import { sidebarAction } from '../../../Store/Actions/sidebarAction'
import { ChatListView } from '../../Partials/Chat/ChatListView'
import { mainQueueAction } from '../../../Store/Actions/mainQueueAction'
import { mobileMainQueueAction } from '../../../Store/Actions/mobileMainQueueAction'
import SearchModal from '../../Modals/SearchModal'
import { Tooltip } from 'reactstrap'

function Index() {
  const {
    socketContext,
    initialQueues,
    isLoadingQueues,
    activeChatCustomer,
    setActiveChatCustomer,
    socketInstance,
    setChatReadOnly,
    updateInAttendance
  } = useContext(AttendanceContext)
  const [inAttendances, setInAttendances] = useState([])
  const [openSidebar, setOpenSidebar] = useState(true)
  const [filteredResults, setFilteredResults] = useState(inAttendances);
  const [unreadMessages, setUnreadMessages] = useState([])
  const [filterValue, setFilterValue] = useState('')
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal)
  const [tooltipOpenDownArrow, setTooltipOpenDownArrow] = useState(false);
  const [tooltipOpenUpArrow, setTooltipOpenUpArrow] = useState(false);
  const [tooltopClearFilters, setClearFilters] = useState(false)
  const [listWithAllFilters, setListWithAllFilters] = useState({
    inputText: null,
    departamentName: null,
    channel: null,
    tags: null,
    transferred: false,
    countFilters: 0,
    orderDesc: true
    
  })

  const toggleTooltipDownArrow = () => setTooltipOpenDownArrow(!tooltipOpenDownArrow);
  const toggleTooltipUpArrow = () => setTooltipOpenUpArrow(!tooltipOpenUpArrow);
  const toggleTooltipClearFilters = () => setClearFilters(!tooltopClearFilters)

  const childreRef = useRef();
  const clearButtonRef = useRef(null);
  const inputRef = useRef(null)


  let chatList =
    filteredResults.length ?
      filteredResults : inAttendances


  const handleInAttendances = async () => {
    const attendance = await getInAttendance()
    setInAttendances(attendance)
    const result = handleAllFilters()
    setFilteredResults(result)
  }

  const filterForDepartament = (arrayForFiltering) => {

    if (listWithAllFilters?.departamentName?.length > 0) {
      const result = arrayForFiltering.filter(item =>
        listWithAllFilters.departamentName.some(filter => filter.label === item.attendance.departmentName)
      );
      return result
    }
    return arrayForFiltering
  }

  const filterForChannel = (arrayForFiltering) => {
    if (listWithAllFilters?.channel?.length > 0) {
      const result = arrayForFiltering.filter(item =>
        listWithAllFilters.channel.some(filter => filter.value === item.channel.id)
      );
      return result
    }
    return arrayForFiltering
  }

  const filterForTags = (arrayForFiltering) => {
    if (listWithAllFilters?.tags?.length > 0) {
      const result = arrayForFiltering.filter(item =>
        listWithAllFilters.tags.some(filter =>
          item.tags.some(tag => tag?.id === filter.value)
        )
      );
      return result
    }
    return arrayForFiltering
  }

  const filterForOnlyTransferred = (arrayForFiltering) => {
    if (listWithAllFilters?.transferred) {
      const result = arrayForFiltering?.filter(item => item.attendance?.transfer === true);
      return result
    }

    return arrayForFiltering
  }

  const filterForInput = (arrayForFiltering) => {
    if (listWithAllFilters?.inputText) {
      const filteredArray = arrayForFiltering.filter(item => {
        return (
          item.name?.toLowerCase().includes(listWithAllFilters.inputText) ||
          item.phone?.includes(listWithAllFilters.inputText) ||
          (Array.isArray(item.externalIds) && item.externalIds?.some(id => id ? id.toString().includes(listWithAllFilters.inputText) : null))
        )
      });
      return filteredArray
    }
    return arrayForFiltering
  }

  const filterForOrder = (arrayForFiltering) => {
    if (listWithAllFilters.orderDesc) {
      return arrayForFiltering
    }
    return arrayForFiltering.reverse()

  }

  const dispatch = useDispatch();
  const { mainQueueSidebar, mobileMainQueueSidebar } = useSelector(state => state);

  const chatSelectHandle = (chat) => {
    socketInstance.emit('chatbot_send_seen', {
      cellPhone: chat.phone,
      channelId: chat.channel.id,
    })
    chat.unreadMessages = 0
    setChatReadOnly({ readOnly: false })
    setActiveChatCustomer(chat)
    dispatch(selectedChatAction(chat));
    dispatch(mobileSidebarAction(false));
    const isMobile = document.body.clientWidth < 768
    if (isMobile) {
      dispatch(mainQueueAction(false));
      dispatch(mobileMainQueueAction(false))
    }
  };

  const closeAction = (e) => {
    e.preventDefault();
    setOpenSidebar(false)
    dispatch(mainQueueAction(false));
    dispatch(mobileMainQueueAction(false));
    dispatch(sidebarAction(false));
    dispatch(mobileSidebarAction(false));
  };

  const sortInAttendancesByMessages = (updatedMessage) => {
    const index = inAttendances.findIndex(msg => msg.phone === updatedMessage.cellPhone && msg.channel.id === updatedMessage.channel.id)
    if (index !== -1) {
      let updatedObject = inAttendances.splice(index, 1)[0];
      updatedObject.lastMessage = updatedMessage
      inAttendances.unshift(updatedObject)
      const result = handleAllFilters()
      setFilteredResults(result)
    }
  }

  const getWSEvent = async () => {
    if (!socketContext) return

    if (socketContext.unreadCount || socketContext.unreadCount === 0) {
      const unreadCount = socketContext

      if (socketContext.unreadCount === 0) {
        setUnreadMessages((prevMessages) =>
          prevMessages.map((msg) => {
            if (msg.from === unreadCount.from && msg.channelId === unreadCount.channelId) {
              return { ...msg, unreadCount: 0 }
            }
            return msg
          })
        )
      }

      setUnreadMessages((prevMessages) => {
        const counterExists = prevMessages.find(msg => msg.from === unreadCount.from && msg.channelId === unreadCount.channelId)
        if (counterExists) {
          const idx = prevMessages.indexOf(counterExists)
          prevMessages.splice(idx, 1)
        }

        return [
          ...prevMessages,
          unreadCount
        ]
      })
    }

    if (socketContext.type === 'inAttendance' && socketContext.eventName === 'chatbot_queue') {
      await handleInAttendances()
      setModal(false)
    }

    if (socketContext.eventName === 'chatbot_message' && socketContext.attendanceType === 'inAttendance') {
      sortInAttendancesByMessages(socketContext)
    }
  }

  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterValue(value)
    setListWithAllFilters((prevFilters) => ({
      ...prevFilters,
      inputText: value
    }))

    if (value === '') {
      const defaultResults =  inAttendances;
      setFilteredResults(defaultResults);
      return;
    }

    const originalArrayCopy = [...inAttendances]

    const filteredArray = originalArrayCopy.filter(item => {
      return (
        item.name?.toLowerCase().includes(value) ||
        item.phone?.includes(value) ||
        (Array.isArray(item.externalIds) && item.externalIds?.some(id => id ? id.toString().includes(value) : null))
      )
    });

    setFilteredResults(filteredArray)

  }

  const handleClearStatesOutModal = () => {
    inputRef.current.value = ''
    setFilteredResults(inAttendances)

  }

  const cLearAllFilters = async () => {
    childreRef.current.handleClearFilters()
    await handleInAttendances()
    setListWithAllFilters((prevFilters) => ({
      ...prevFilters,
      departamentName: null,
      channel: null,
      tags: null,
      transferred: false,
      countFilters: 0
    }))
  }

  const handleAllFilters = () => {
    let filteredArray = [...inAttendances];

    filteredArray = filterForTags(filteredArray);
    filteredArray = filterForDepartament(filteredArray);
    filteredArray = filterForChannel(filteredArray);
    filteredArray = filterForOnlyTransferred(filteredArray);
    filteredArray = filterForInput(filteredArray);
    filteredArray = filterForOrder(filteredArray);

    return filteredArray;
  }

  useEffect(() => {
    const result = handleAllFilters()
    setFilteredResults(result)
  }, [listWithAllFilters, inAttendances])

  useMemo(() => {
    getWSEvent()
    dispatch(sidebarAction('Chats'))
  }, [socketContext])

  useMemo(async () => {
    if (updateInAttendance && socketContext.type !== 'inAttendance') {
      cLearAllFilters()
      await handleInAttendances()
    }
  }, [updateInAttendance])

  useMemo(() => {
    if (!isLoadingQueues) {
      setInAttendances(initialQueues.inAttendance)
    }
  }, [isLoadingQueues])

  return (
    <div className={`sidebar-group ${openSidebar || mobileMainQueueSidebar ? "mobile-open" : ""}`}>
      <div className={openSidebar || mainQueueSidebar ? 'sidebar active' : 'sidebar'}>
        <header>
          <span className='d-flex align-items-center' style={{ gap: 10 }} id="teste">Em atendimento {inAttendances.length ? <div className="new-message-count">{inAttendances.length > 90 ? '99+' : inAttendances.length}</div> : ''}</span>
          <a href="/#/" onClick={(e) => closeAction(e)}
            className="btn btn-light">
            <i className="ti ti-close" id="openQueues"></i>
          </a>
        </header>
        <form onSubmit={(e) => e.preventDefault()}
          style={{ marginBottom: '0.1rem' }}
        >
          <div className='d-flex'>
            <input type="text"
              className="form-control"
              placeholder="Procurar conversa"
              onChange={handleFilter}
              ref={inputRef}
            />
          </div>
          <div className='d-flex justify-content-center mt-2'>

            <button className="btn btn-Light btn-sm ml-2 rounded border border-primary "
              onClick={() => {
                modalToggle()
                inputRef.current.value = ''
              }}
            >
              <i className="fa fa-filter mt-1" aria-hidden="true" />
              <span className='ml-2 mt-1'>Todos os filtros</span>
              {
                listWithAllFilters?.countFilters > 0 &&
                <span className='new-message-count ml-2'>{ listWithAllFilters?.countFilters}</span>
              }
            </button>
            {
              listWithAllFilters?.countFilters > 0 && (
                <>
                  <button
                    className='fa fa-times arrow-calendar arrow-calendar rounded-pill ml-1 text-danger'
                    id='clearButton'
                    ref={clearButtonRef}
                    name='clearButton'
                    onClick={cLearAllFilters}
                    style={{ border: '2px solid red' }}
                  >
                  </button>

                  <Tooltip
                    placement="top"
                    isOpen={tooltopClearFilters}
                    target="clearButton"
                    toggle={toggleTooltipClearFilters}
                  >
                    Remover todos os filtros
                  </Tooltip>
                </>
              )
            }
            <div className="btn-group btn-group-toggle ml-2" data-toggle="buttons">
              <label
                className={`btn btn-secondary ${listWithAllFilters.orderDesc ? 'active' : ''} btn-sm`}
                id="descOrder"
                onClick={() => setListWithAllFilters((prevFilters) => ({
                  ...prevFilters,
                  orderDesc: true
                }))}
              >
                <input type="radio"
                  name="asc"
                  autoComplete="off" />
                <i
                  className="fa fa-long-arrow-down"
                  aria-hidden="true"
                  style={{
                    position: 'relative',
                    marginLeft: '2px',
                    transform: 'scaleY(1.3)',
                    marginTop: '5px'
                  }}
                />
              </label>
              <Tooltip
                placement="top"
                isOpen={tooltipOpenDownArrow}
                target="descOrder"
                toggle={toggleTooltipDownArrow}
              >
                Ordenar pela mais recente
              </Tooltip>
              <label
                className={`btn btn-secondary ${listWithAllFilters.orderDesc === false ? 'active' : ''} btn-sm`}
                onClick={() => setListWithAllFilters((prevFilters) => ({
                  ...prevFilters,
                  orderDesc: false
                }))}
                id="ascOrder"
              >
                <input type="radio"
                  name="asc"
                  autoComplete="off" />
                <i
                  className="fa fa-long-arrow-up"
                  aria-hidden="true"
                  style={{
                    position: 'relative',
                    marginRight: '2px',
                    transform: 'scaleY(1.3)',
                    marginTop: '5px'
                  }}
                />
              </label>
              <Tooltip
                placement="top"
                isOpen={tooltipOpenUpArrow}
                target="ascOrder"
                toggle={toggleTooltipUpArrow}
              >
                Ordenar pela mais antiga
              </Tooltip>
            </div>
          </div>
        </form>
        <div className="sidebar-body">
          {!inAttendances.length ?
            <div className="chat">
              <div className="chat-body no-message">
                <div className="no-message-container">
                  <i className="fa fa-exclamation-circle"></i>
                  <p style={{ textAlign: 'center' }}>No momento não há atendimentos.</p>
                </div>
              </div>
            </div>
            : !filteredResults.length && filterValue || (filteredResults.length === 0 && listWithAllFilters?.countFilters > 0) ?
              <div className="chat">
                <div className="chat-body no-message">
                  <div className="no-message-container">
                    <i className="fa fa-exclamation-circle"></i>
                    <p style={{ textAlign: 'center' }}>No momento não foi encontrado um atendimento com esse filtro.</p>
                  </div>
                </div>
              </div>
              :
              <PerfectScrollbar>
                <ul className="list-group list-group-flush">
                  {chatList && chatList.map((chat, i) => (
                    <ChatListView
                      chat={chat}
                      key={i}
                      activeChatCustomer={activeChatCustomer}
                      unreadMessages={unreadMessages}
                      chatSelectHandle={chatSelectHandle}
                    />
                  ))}
                </ul>
              </PerfectScrollbar>
          }
        </div>
      </div>
      <SearchModal
        modal={modal}
        modalToggle={modalToggle}
        inAttendances={inAttendances}
        setFilteredResults={setFilteredResults}
        handleClearStatesOutModal={handleClearStatesOutModal}
        ref={childreRef}
        setListWithAllFilters={setListWithAllFilters}
        handleAllFilters={handleAllFilters}
      />
    </div>
  )
}

export default Index
