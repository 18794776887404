import React, { useState, useRef } from "react";
import { default as ReactSelect, components } from "react-select";

const MoreSelectedBadge = ({ items, singular, plural }) => {
  const title = items.join(", ");
  const length = items.length;
  //const label = `+ ${length} ${length !== 1 ? "canais selecionados" : "canal selecionado"} `;
  const label = `+ ${length} ${length !== 1 ? plural : singular} `;

  return (
    <div //style={style} 
      title={title}>
      {label}
    </div>
  );
};

function arraysComparations(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let obj1 of array1) {
    let objectFound = false;
    for (let obj2 of array2) {
      if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
        objectFound = true;
        break;
      }
    }
    if (!objectFound) {
      return false;
    }
  }
  return true;
}

const MultiValue = ({ index, getValue, singular, plural, ...props }) => {
  const maxToShow = 2;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} singular={singular} plural={plural} />
  ) : null;
};

const MultiSelect = (props) => {
  const [selectInput, setSelectInput] = useState("");
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef(props.selectAllName);
  const allOption = { value: "*", label: selectAllLabel.current };
  const singular = props.messageForSingular;
  const plural = props.messageForPlural;
  const filterOptions = (options, input) => {
    if (!options?.length) return []
    return options?.filter(({ label }) => label.toLowerCase().includes(input.toLowerCase()));
  }

  const comparator = (v1, v2) =>
    (v1.value) - (v2.value);

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          key={props.value}
          type="checkbox"
          checked={props.isSelected || isAllSelected.current}
          onChange={() => { }}
          style={{ marginRight: "5px" }}
        />
        <span
          style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          title={props.label}
        >
          {props.label}
        </span>
      </div>
    </components.Option>
  );


  const Input = (props) => (
    <>
      {selectInput.length === 0 ? (
        <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
          {props.children}
        </components.Input>
      ) : (
        <div style={{ border: "1px dotted gray" }}>
          <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
            {props.children}
          </components.Input>
        </div>
      )}
    </>
  );

  const customFilterOption = ({ value, label }, input) =>
    (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (
    inputValue,
    event
  ) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected) => {
    const currentValue = Array.isArray(props.value) ? props.value : [];
    const optionsArray = Array.isArray(props.options) ? props.options : [];
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
        JSON.stringify(selected.sort(comparator)))
    ) {

      return props.onChange(
        [
          ...currentValue,
          ...optionsArray.filter(
            ({ label }) =>
              label
                .toString()
                .toLowerCase()
                .includes(selectInput?.toLowerCase()) &&
              currentValue.filter(
                (opt) => opt.label === label.toString()
              ).length === 0
          ),
        ].sort(comparator)
      );
    } else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
      JSON.stringify(filteredOptions)
    ) {
      return props.onChange(selected);
    } else {
      return props.onChange(
        currentValue.filter(
          ({ label }) =>
            !label
              .toString()
              .toLowerCase()
              .includes(selectInput?.toLowerCase())
        )
      );
    }
  };

  const customStyles = {
    multiValueLabel: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    multiValueRemove: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    valueContainer: (base) => ({
      ...base,
      flexWrap: "nowrap",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: 'ellipsis',
      maxWidth: '25vw',

    }),
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
              ? styles.backgroundColor
              : isFocused && isSelected
                ? "#DEEBFF"
                : null,
        color: isSelected ? null : null,
      };
    },
    menu: (def) => ({ ...def, zIndex: 9999 }),
  };

  if (props.isSelectAll && props.options.length !== 0) {
    isAllSelected.current = arraysComparations(filteredSelectedOptions, filteredOptions);
    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `${props.firstNameSelect} (${filteredOptions.length}) ${props.lastNameSelec}`;
      else
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} ${props.lastNameSelec}`;
    } else selectAllLabel.current = props.selectAllName;

    allOption.label = selectAllLabel.current;

    return (
      <ReactSelect
        {...props}
        inputValue={selectInput}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        options={[allOption, ...props.options]}
        onChange={handleChange}
        components={{
          Option: Option,
          MultiValue: (props) => <MultiValue {...props} singular={singular} plural={plural} />,
          Input: Input,
          ...props.components,
        }}
        filterOption={customFilterOption}
        menuPlacement={props.menuPlacement ?? "auto"}
        styles={customStyles}
        isMulti
        closeMenuOnSelect={false}
        tabSelectsValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      filterOption={customFilterOption}
      components={{
        Input: Input,
        ...props.components,
      }}
      menuPlacement={props.menuPlacement ?? "auto"}
      onKeyDown={onKeyDown}
      tabSelectsValue={false}
      hideSelectedOptions={true}
      backspaceRemovesValue={false}
      blurInputOnSelect={true}
    />
  );
};

export default MultiSelect;
