import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Input, Label, Modal, ModalBody, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import Select from 'react-select';
import { getChannelsActives } from '../../Services/Attendance-Service';
import { getAllCampaignTypes, postCampaign, findAllAgendas, findMediaTypes, updateCampaign, findCampaignById } from '../../Services/Campaign-Service';
import { useToast } from '../Partials/Toast';

import 'react-bootstrap-tagsinput/dist/index.css'
import AddButton from '../Components/AddButton';
import 'react-datepicker/dist/react-datepicker.css'
import { Overlay } from '../Partials/Overlay';
import PreviewAttachmentModal from './PreviewAttachmentModal';
import { AttendanceContext } from '../../Context/AttendanceContext';
import DatepickerCampaign from '../Components/DatepickerCampaign'
import { useRef } from 'react';
import Emojis from '../Partials/Emojis';
import MultiSelect from '../Partials/MultiSelect';
import { Dropzone } from '@dropzone-ui/react';
import { isAfter, addMinutes } from 'date-fns';
import MediaTypeComponent from '../Components/MediaTypeComponent'
import ValidationCampaignStatus from '../Partials/ValidationCampaignStatus';
import { getAllGroupsById, findGroupById } from '../../Services/Group-Service';

function AddCampaign({
  onCloseModal,
  onAddCampaign,
  editingCampaign,
  campaignInfo,
  onEditCampaign,
  campaignTypesList,
  setCampaignTypesList
}) {

  const { showSuccessToast, showErrorToast } = useToast();
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const [channels, setChannels] = useState([])
  const [chosenChannels, setChosenChannels] = useState([])
  const [choosedTypeMedia, setChoosedTypeMedia] = useState('')
  const [campaignTypes, setCampaignTypes] = useState('')
  const [campaignName, setCampaignName] = useState('')
  const [paddingInput, setPaddingInput] = useState()
  const [inputMsg, setInputMsg] = useState('')
  const [details, setDetails] = useState('')
  const [schedulingDate, setSchedulingDate] = useState([])
  const inputRef = useRef(null)
  const [messageSubmitted, setMessageSubmitted] = useState('')
  const [mediaTypeList, setMediaTypeList] = useState([])
  const [acceptedFiles, setAcceptedFiles] = useState([])
  const { showWarningToast } = useToast()
  const { setAttachment, setOpenModalAttachment, } = useContext(AttendanceContext)
  const [acceptedFilesInput, setAccepprtedFilesInput] = useState('')
  const [selectedDateInitial, setSelectedDateInitial] = useState(null);
  const [selectedDateFinal, setSelectedDateFinal] = useState(null);
  const [loadingSave, setLoadingSave] = useState(false)
  const [detailsEdit, setDetailsEdit] = useState('')
  const [groupsList, setGroupList] = useState([])
  const [chosenGroups, setChosenGroups] = useState('')
  const [campaignInfoById, setCampaignInfoById] = useState('')
  const [extensionFromMime, setExtensionFromMime] = useState({})
  const [extensionsForDefaultPreview, setExtensionsForDefaultPreview] = useState([])

  let findTypeOnEdit;

  const getByDayOfWeek = (dayOfWeek) => {
    return schedulingDate?.find((f) => {
      return f.dayOfWeek === dayOfWeek
    })
  }

  const isDisabled = date => {
    const dayOfWeek = date?.getDay();
    const dayOfWeekNamed = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    const selectedDayOfWeek = getByDayOfWeek(dayOfWeekNamed[dayOfWeek]);
    if (schedulingDate?.length > 1) {
      let hour = date?.getHours() < 10 ? `0${date?.getHours()}` : date?.getHours();
      let minutes = date?.getMinutes() < 10 ? `0${date?.getMinutes()}` : date?.getMinutes();
      const isAgendaValid = selectedDayOfWeek?.times.includes(`${hour}:${minutes}`);
      const currentDate = new Date();
      const latestAllowedDate = addMinutes(currentDate, +5);
      const selectedDateTime = new Date(date);
      const isAfterTime = isAfter(selectedDateTime, latestAllowedDate);
      return isAgendaValid && isAfterTime
    }
    return 'noAgenda'
  };

  const handleGetChannels = async () => {
    const res = await getChannelsActives()
    setChannels
      (res.map(channel =>
      ({
        value: channel._id,
        label: channel.shortName || channel.name,
      })));

    if (res?.length === 1) {
      setChosenChannels([{
        value: res[0]?._id,
        label: res[0]?.shortName || res[0]?.name
      }])
    }
  }

  const handleSetChosenGroups = async (items) => {
    if (!Array.isArray(items)) {
      return;
    }
    const res = await Promise.all(items?.map(group => findGroupById(group.id)));
    setGroupList(res.flat().map(group => {
      return {
        value: group._id,
        label: group.name,
      }
    }))
    const result = items.map(item => {
      const match = res.find(res => res._id === item.id);
      return match ? { value: item.id, label: match.name } : null;
    }).filter(item => item !== null);
    setChosenGroups(result)
  }

  const handleSetChosenChannels = async (items) => {
    const res = await getChannelsActives()
    setChannels
      (res.map(channel =>
      ({
        value: channel._id,
        label: channel.shortName || channel.name,
      })));
    if (!Array.isArray(items)) {
      return;
    }
    const result = items.map(item => {
      const match = res.find(res => res._id === item.id);
      return match ? { value: item.id, label: match.name } : null;
    }).filter(item => item !== null);
    setChosenChannels(result)
  }

  function getMineTypes(array) {
    var mimetypes = [];
    array.forEach(function (obj) {
      obj.mimetypes.forEach(function (mimetype) {
        mimetypes.push(mimetype);
      });
    });

    return mimetypes;
  }

  const formaterExtendionsFromMime = (extensiosFromApi) => {
    const mimetypeFormated = {};

    extensiosFromApi.forEach(item => {
      item.mimetypes.forEach(mimetype => {
        if (mimetype === "application/pdf") {
          mimetypeFormated[mimetype] = "PDF";
        } else {
          mimetypeFormated[mimetype] = item.type.toUpperCase();
        }
      });
    });

    setExtensionFromMime(mimetypeFormated)
  }

  const getextensionsForDefaultPreview = (extensiosFromApi) => {

    const mimeTypesForDefaultPreview = extensiosFromApi
      .filter(item => item.type === "document")
      .flatMap(item => item.mimetypes)
      .filter(mimetype => mimetype !== "application/pdf")

    setExtensionsForDefaultPreview(mimeTypesForDefaultPreview)
  }

  const handleMediaTypes = async (id, campaignInfoById) => {
    const res = await findMediaTypes(id);
    formaterExtendionsFromMime(res)
    getextensionsForDefaultPreview(res)
    setAcceptedFiles(getMineTypes(res));
    const textMediaType = 'text/plain';
    const documentName = 'Documento';
    const textName = 'Texto';
    const mediaTypeList = res.map(media => ({
      label: media.name,
      value: media.type,
      files: media.mimetypes,
      enableFile: media.enableFile,
      enableDescription: media.enableDescription
    }));

    setMediaTypeList(mediaTypeList);

    if (editingCampaign) {
      let choosedType;
      if (campaignInfoById?.mediaType !== textMediaType) {
        const findMediaEdit = res.find(item => item.mimetypes.some(type => type === campaignInfoById.mediaType));
        choosedType = MediaTypes(findMediaEdit, campaignInfoById);
      } else {
        if (campaignInfoById?.content) {
          choosedType = MediaTypes(res?.find(item => item?.name === documentName));
        } else {
          choosedType = MediaTypes(res?.find(item => item?.name === textName));
        }
      }
      setChoosedTypeMedia(choosedType);
    }
  }

  const MediaTypes = (media, campaignInfoById) => {
    return ({
      label: media?.name,
      value: campaignInfoById?.mediaType,
      files: media?.mimetypes,
      enableFile: media?.enableFile,
      enableDescription: media?.enableDescription
    })
  }


  const handleDisableSaveButton = () => {
    const shouldDisable =
      ValidationCampaignStatus(campaignInfo) ||
      !campaignName ||
      !campaignTypes ||
      !chosenChannels.length > 0 ||
      !choosedTypeMedia ||
      !selectedDateInitial ||
      (campaignTypes.value === 'offer' && !selectedDateFinal) ||
      (choosedTypeMedia.value === 'text' && !inputMsg) ||
      (choosedTypeMedia.value !== 'text' && !details)

    setDisableSaveButton(shouldDisable);
  }
  const handleSave = async (start = false) => {
    setLoadingSave(true)
    const chosenChannelsList = chosenChannels.map(channel => ({
      id: channel.value
    }))

    const chosenGroupList = Array.isArray(chosenGroups)
      ? chosenGroups.map(group => ({ id: group?.value }))
      : [];

    const formaterDate = (DateChosen) => {
      const data = new Date(DateChosen)
      const year = String(data.getFullYear());
      const month = String(data.getMonth() + 1).padStart(2, '0')
      const day = String(data.getDate()).padStart(2, '0')
      const hour = String(data.getHours()).padStart(2, '0');
      const minute = String(data.getMinutes()).padStart(2, '0');
      const second = String(data.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day}T${hour}:${minute}:${second}`
    }

    const body = {
      name: campaignName,
      description: inputMsg || null,
      mediaType: choosedTypeMedia.value === 'text' ? choosedTypeMedia.files[0] : details.type || choosedTypeMedia.value,
      dateFrom: formaterDate(selectedDateInitial),
      dateTo: formaterDate(selectedDateFinal) || null,
      types: [campaignTypes.value],
      content: details?.content || detailsEdit,
      channels: chosenChannelsList,
      groups: chosenGroupList
    }

    try {
      const isEditing = editingCampaign
      if (isEditing) {
        const payload = {
          id: campaignInfo._id,
          body
        }
        if (details === detailsEdit) {
          payload.body.content = null
        }
        await updateCampaign(payload)
        setLoadingSave(false)
        modalToggle()
        onEditCampaign(true)
        showSuccessToast('Campanha atualizada com sucesso.')
        return
      }
      await postCampaign(body)
      setLoadingSave(false)
      onAddCampaign(true)
      modalToggle()
      showSuccessToast('Campanha cadastrada com sucesso.')
    } catch (error) {
      console.error('An error occurred:', error)
      setLoadingSave(false)
    }
  }
  const setFormEditingCampaign = (campaignInfoById) => {
    setSelectedDateInitial(new Date(campaignInfoById?.dateFrom))
    setSelectedDateFinal(new Date(campaignInfoById.dateTo))
    setCampaignName(campaignInfoById.name)
    setInputMsg(campaignInfoById?.description || '')
    setDetails(campaignInfoById.content)
    setDetailsEdit(campaignInfoById.content)
    handleSetChosenGroups(campaignInfoById.groups)
    handleSetChosenChannels(campaignInfoById.channels)
  }

  const resetForm = () => {
    setChosenChannels([])
    setCampaignName('')
    setCampaignTypes('')
    setChoosedTypeMedia('')
    setSelectedDateInitial(null)
    setSelectedDateFinal(null)
    setDetails('')
    setInputMsg('')
    setMediaTypeList([])
    setLoadingSave(false)
    setDisableSaveButton(false)
    setDetailsEdit('')
    setGroupList([])
    setChosenGroups('')
    setCampaignInfoById('')
  }

  useMemo(() => {
    if (editingCampaign) {
      modalToggle()
    }
  }, [editingCampaign])

  const onDropFile = (droppedFile) => {
    if (!droppedFile.length) return
    const { errors, file } = droppedFile[0]
    if (errors?.length) {
      showWarningToast('Tipo de arquivo não permitido.')
      return
    }
    setAttachment(file)
    setOpenModalAttachment(true)
  };
  const hasOffer = campaignTypes?.value === 'offer' ? true : false

  const detectPasteFile = (acceptedFiles) => {
    document.addEventListener('paste', function (evt) {
      const clipboardItems = evt.clipboardData.items;
      const items = [].slice.call(clipboardItems).filter(function (item) {

        const isAllowedFile = acceptedFiles?.includes(item.type)
        return isAllowedFile
      }).filter(item => item.kind === 'file')

      if (items.length === 0) {
        return;
      }
      const item = items[0];
      const blob = item.getAsFile()
      let file = new File([blob], blob.name, { type: blob.type, lastModified: new Date().getTime() }, 'utf-8')
      handleSendAttachment({ target: { files: [file] } })
    });
  }

  const handleOpenAttachments = (type) => {
    const typesMimesString = type.files?.join(', ');
    const acceptedFilesInputString = String(typesMimesString);
    setAccepprtedFilesInput(acceptedFilesInputString)

  }

  const handleGetInputAttachements = () => {
    document.getElementById('inputAttachment').click()
  }

  const handleGetChoosedTypeMedia = (details) => {
    const filteredMedia = mediaTypeList.filter(media => media.files?.includes(details?.type));
    const media = filteredMedia[0]
    setChoosedTypeMedia(media)
  }

  const handleSendAttachment = (e) => {
    if (e.target.files) {
      const file = e.target.files[0]
      setAttachment(file)
      setOpenModalAttachment(true)
    }
  }

  const pasteIntoInput = (text) => {
    const el = inputRef.current
    el.focus()

    if (typeof el.selectionStart === 'number' && typeof el.selectionEnd === 'number') {
      const val = el.value
      const selStart = el.selectionStart
      el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd)
      el.selectionEnd = el.selectionStart = selStart + text.length
    } else if (typeof document.selection !== 'undefined') {
      const textRange = document.selection.createRange()
      textRange.text = text
      textRange.collapse(false)
      textRange.select()
    }
  }

  const handleEnter = (e) => {
    if (e.keyCode === 13 && e.shiftKey) {
      if (e.type === 'keypress' || e.type === 'keydown') {
        pasteIntoInput('\n')
      }
      e.preventDefault()
    }
  }

  const handleChange = (e) => {
    setInputMsg(e)
  }

  const getAgenda = async (id) => {
    const response = await findAllAgendas(id)
    setSchedulingDate(response)
  }

  const handleChangeChannel = (selected) => {
    const uniqueData = selected.filter((item, index, self) =>
      index === self.findIndex((t) => t.value === item.value)
    )
    setChosenChannels(uniqueData);
  };

  const handleGetGroupsForSend = (selected) => {
    setChosenGroups(selected)
  }

  const ComponentGroupPopover = ({ data }) => {
    const [popoverGroupOpen, setPopoverGroupOpen] = useState(false);
    const toggleGroupOpen = () => setPopoverGroupOpen(!popoverGroupOpen);

    return (
      <>
        <div
          id={`groupList-${data?._id}`}
          onMouseEnter={toggleGroupOpen}
          onMouseLeave={toggleGroupOpen}
        >
          <i className="fa fa-users bg-dark p-2 mx-2 rounded-lg mb-1" />
        </div>
        <Popover
          placement="right"
          isOpen={popoverGroupOpen}
          target={`groupList-${data._id}`}
          style={{ zIndex: 999 }}
        >
          <PopoverHeader>Grupos vinculados</PopoverHeader>
          <PopoverBody>
            <table>
              <tbody>
                {data?.map((group, i) => (
                  <tr key={i}>
                    <td>{group?.label}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PopoverBody>
        </Popover>
      </>
    );
  };

  const getAcceptedFiles = () => {
    const mimeString = acceptedFiles.reduce((acc, curr, index, array) => {
      return acc + curr + (index < array.length - 1 ? ', ' : '');
    }, '{') + '}';
    return mimeString
  }

  const groupsLength = groupsList?.length < 1

  useEffect(() => {
    if (campaignTypes) {
      getAgenda(campaignTypes.id);
      handleMediaTypes(campaignTypes?.id, campaignInfoById)
    }
  }, [campaignTypes?.id]);

  useEffect(() => {

    const handleGetGroupsChannel = async (channelId) => {
      return await getAllGroupsById(channelId);
    };
    const getFetchData = async () => {
      try {
        const res = await Promise.all(chosenChannels.map(channel => handleGetGroupsChannel(channel?.value)));
        setGroupList(res.flat().map(group => {
          return {
            value: group._id,
            label: group.name,
          }
        }))
      } catch (error) {
        console.error('An error occurred:', error)
      }
    }


    if (chosenChannels) {
      getFetchData()
    }
  }, [chosenChannels])

  useEffect(() => {

    if (details) {
      handleGetChoosedTypeMedia(details)
    }
  }, [details])

  let acceptedFilesFormated
  useEffect(() => {

    acceptedFilesFormated = getAcceptedFiles()

    detectPasteFile(acceptedFiles)
    document.getElementById('form-buttons') && setPaddingInput(document.getElementById('form-buttons').clientWidth)
  }, [acceptedFiles])


  useEffect(() => {

    if (!ValidationCampaignStatus(campaignInfo) && modal) {
      handleGetChannels()
    }

    const handleAsyncOperations = async () => {
      if (!modal) {
        resetForm();
        return;
      }

      if (modal) {
        findTypeOnEdit = await campaignTypesList.find(campaignType => campaignType?.value === campaignInfo?.types[0])
        setCampaignTypes(findTypeOnEdit)
        if (findTypeOnEdit) {
          await getAgenda(findTypeOnEdit?.id)
        }
      }

      if (modal && editingCampaign && campaignInfo) {
        try {
          const campaignInfoByIdValue = await findCampaignById(campaignInfo._id);
          setCampaignInfoById(campaignInfoByIdValue);
          setFormEditingCampaign(campaignInfoByIdValue);
          handleMediaTypes(findTypeOnEdit?.id, campaignInfo)
        } catch (error) {
          console.error('Error fetching campaign by ID:', error);
        }
      }
      modal && handleDisableSaveButton()
    };
    handleAsyncOperations()
  }, [modal, campaignInfo])

  useEffect(() => {
    if (choosedTypeMedia) {
      handleOpenAttachments(choosedTypeMedia)
    }
    handleDisableSaveButton()
    return () => handleDisableSaveButton()
  }, [campaignName, campaignTypes, choosedTypeMedia, selectedDateInitial, inputMsg, details, selectedDateFinal, chosenGroups, chosenChannels])


  return (
    < >
      <AddButton tooltipText='Adicionar campanha' onClick={modalToggle} id={'add-btn-1'} className='ml-auto' />
      <Modal isOpen={modal}
        toggle={(toggle) => { modalToggle(toggle) }}
        centered className='call modal-dialog-zoom'
        size='lg'
        zIndex={999}
        onClosed={(toggle) => { onCloseModal(toggle) }}>
        {loadingSave && (
          <div className='spinner-box'>
            <i className='fa fa-spinner'
              style={{
                animation: 'spin 3s linear infinite',
                fontSize: 42
              }}></i>
          </div>
        )}
        <button className={'btn btn-light btn-modal-close'} onClick={modalToggle}>
          <i className='fa fa-times'></i>
        </button>
        <Overlay />
        <div style={{ backgroundColor: '#e6e6e6' }}>
          <Dropzone multiple={false}
            onChange={onDropFile}
            footer={false}
            header={false}
            clickable={false}
            label=''
            accept={acceptedFilesFormated}
            disabled={acceptedFiles.length > 0 ? false : true}
            autoClean>
            <ModalBody>
              <div className='call-background' style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
              <div className='mb-2'>
                <h4 style={{ color: 'black' }}>{editingCampaign ? 'Editar campanha' : 'Adicionar campanha'}</h4>
                <div className='transfer-info-box' >
                  <div style={{ padding: '0 50px', color: 'black', fontWeight: 'bold' }}>
                    <div className='d-flex align-items-end'>
                      <div className='w-100 text-left ml-2'>
                        <Label for='nameCampaign' className='mt-4'>Nome:</Label>
                        <Input type='text'
                          placeholder='Nome da campanha'
                          name='nameCampaign'
                          id='name'
                          style={{ maxHeight: 200 }}
                          onChange={(e) =>
                            setCampaignName(e.target.value)}
                          autoComplete='off'
                          value={campaignName}
                          readOnly={ValidationCampaignStatus(campaignInfo)} />
                      </div>
                      <div className='w-100 text-left ml-2'>
                        <Label for='typecampaign' className='mt-4'>Tipo:</Label>
                        <Select
                          options={campaignTypesList}
                          name='typecampaign'
                          placeholder='Selecione o tipo de campanha'
                          classNamePrefix={'multiselect'}
                          menuPortalTarget={document.querySelector('body')}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            valueContainer: css => ({
                              ...css,
                              flexWrap: 'nowrap',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              backgroundColor: ValidationCampaignStatus(campaignInfo) ? '#e9ecef' : '',
                              border: ValidationCampaignStatus(campaignInfo) ? '1px solid #ced4da' : '',

                            })
                          }}
                          onChange={(e) => {
                            setCampaignTypes(e);
                            setSelectedDateInitial(null);
                            setSelectedDateFinal(null);
                            setMediaTypeList([]);
                            setChoosedTypeMedia('');
                            setDetailsEdit('')
                            //campaignTypes?.enableContactGroup && editingCampaign && (setChosenChannels([]));
                          }}
                          value={campaignTypes}
                          isDisabled={ValidationCampaignStatus(campaignInfo)}
                        />
                      </div>
                    </div>
                    <div className='d-flex align-items-end'>
                      <div className='w-100 text-left ml-2' style={{ maxWidth: '48.5%' }}>
                        <Label for='channels'>Selecionar canal(is):</Label>
                        {
                          channels.length > 1 ? (
                            <MultiSelect
                              key='example_id'
                              options={channels}
                              onChange={(selectedOption) => {
                                handleChangeChannel(selectedOption);
                                setChosenGroups('');
                              }}
                              value={chosenChannels}
                              isSelectAll={true}
                              menuPlacement='bottom'
                              placeholder='Selecione'
                              selectAllName='Selecionar todos os canais'
                              firstNameSelect='Todos os'
                              lastNameSelec='canais selecionados'
                              isMulti={false}
                              messageForSingular='canal selecionado'
                              messageForPlural='canais selecionados'
                              isDisabled={ValidationCampaignStatus(campaignInfo)}
                            />
                          ) : (
                            <Select
                              options={channels}
                              name='channels'
                              closeMenuOnSelect={false}
                              noOptionsMessage={() => 'Nenhum canal disponível.'}
                              placeholder='Selecione'
                              classNamePrefix='multiselect'
                              menuPortalTarget={document.querySelector('body')}
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                valueContainer: css => ({
                                  ...css,
                                  flexWrap: 'nowrap',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  backgroundColor: ValidationCampaignStatus(campaignInfo) ? '#e9ecef' : '',
                                  border: ValidationCampaignStatus(campaignInfo) ? '1px solid #ced4da' : '',
                                }),
                              }}
                              isMulti={false}
                              onChange={e => {
                                setChosenChannels([e]);
                                setChosenGroups('');
                              }}
                              value={chosenChannels}
                              isDisabled={ValidationCampaignStatus(campaignInfo)}
                            />
                          )
                        }
                      </div>
                      <div className='w-100 text-left ml-2'>
                        <MediaTypeComponent
                          mediaTypeList={mediaTypeList}
                          setDetails={setDetails}
                          setDetailsEdit={setDetailsEdit}
                          setChoosedTypeMedia={setChoosedTypeMedia}
                          choosedTypeMedia={choosedTypeMedia}
                          isDisabled={ValidationCampaignStatus(campaignInfo)}
                        />
                      </div>
                    </div>
                    {campaignTypes?.enableContactGroup && groupsList && (
                      <div className='d-flex align-items-end'>
                        <div className='w-100 text-left ml-2'>
                          <Label for='typecampaign' className='mt-4'>Grupo contatos (opcional):</Label>
                          <MultiSelect
                            key='example_group'
                            options={groupsList}
                            onChange={handleGetGroupsForSend}
                            value={chosenGroups}
                            isSelectAll={true}
                            menuPlacement={'bottom'}
                            placeholder={groupsLength ? ' Canal(is) sem grupo(s)' : 'Selecione'}
                            selectAllName='Selecionar todos os grupos'
                            firstNameSelect='Todos os'
                            lastNameSelec='grupos selecionados'
                            messageForSingular='grupo selecionado'
                            messageForPlural='grupos selecionados'
                            isMult={false}
                            styles={{
                              menuPortal: base => ({ ...base, zIndex: 9999 }),
                              valueContainer: css => ({
                                ...css,
                                backgroundColor: ValidationCampaignStatus(campaignInfo) || groupsLength ? '#e9ecef' : '',
                                border: ValidationCampaignStatus(campaignInfo) || groupsLength ? '1px solid #ced4da' : ''
                              })
                            }}
                            isDisabled={ValidationCampaignStatus(campaignInfo) || groupsLength}
                          />
                        </div>
                        {chosenGroups?.length > 1 && editingCampaign && (
                          <ComponentGroupPopover data={chosenGroups} />
                        )}

                      </div>
                    )}
                    <div className='d-flex align-items-end'>
                      <DatepickerCampaign
                        hasOffer={hasOffer}
                        filterTime={isDisabled}
                        selectedDateInitial={selectedDateInitial}
                        selectedDateFinal={selectedDateFinal}
                        setSelectedDateInitial={setSelectedDateInitial}
                        setSelectedDateFinal={setSelectedDateFinal}
                        schedulingDate={schedulingDate}
                        isDisabled={ValidationCampaignStatus(campaignInfo)}
                      />
                    </div>
                    <div className='d-flex align-items-end'>
                      <div className='text-left w-100 ml-2'>
                        <Label className='w-100 mt-4'>Arquivo:</Label>
                        <Overlay />
                        <Input
                          disabled={true}
                          placeholder={details?.name || campaignInfo?.content || 'Selecione o arquivo'}
                        />
                        <PreviewAttachmentModal
                          details={details}
                          setDetails={setDetails}
                          extensionFromMime={extensionFromMime}
                          extensionsForDefaultPreview={extensionsForDefaultPreview} />
                      </div>
                      <AddButton
                        id={'add-btn-2'}
                        tooltipText='Adicionar arquivo'
                        onClick={(e) => handleGetInputAttachements(e)}
                        disabledButton={choosedTypeMedia?.enableFile === false || !choosedTypeMedia}
                      />
                      <Input
                        type='file'
                        id='inputAttachment'
                        onChange={(e) => { handleSendAttachment(e); e.target.value = null }}
                        style={{ display: 'none' }}
                        accept={acceptedFilesInput}

                      />
                    </div>
                    <div className='w-100 mt-4 text-left ml-2'>
                      <div>
                        {choosedTypeMedia?.enableDescription !== false &&
                          <>
                            <Label>{choosedTypeMedia?.enableFile === false ? 'Texto:' : 'Legenda:'}</Label>
                            <div className='d-flex align-items-center'>
                              <Emojis
                                onChange={handleChange}
                                inputMsg={inputMsg}
                                messageSubmitted={messageSubmitted}
                                closeOnChange pickerBoxStyle={{ bottom: 0, left: 60, top: 140 }} />
                              <Input
                                type='textarea'
                                onKeyDown={handleEnter}
                                ref={inputRef}
                                className='form-control'
                                placeholder='Legenda...'
                                value={inputMsg}
                                onChange={(e) => { setInputMsg(e.target.value) }}
                                id='input-chat'
                                style={{ paddingRight: paddingInput, resize: 'none', height: 100, border: 0, marginLeft: 10 }}
                                autoComplete='off'
                                readOnly={ValidationCampaignStatus(campaignInfo)}
                              />
                            </div>
                          </>
                        }
                      </div>
                    </div>
                    <div className='text-right ml-auto'>
                      <button
                        type="button"
                        className="btn btn-primary mt-4 mr-1"
                        data-dismiss="modal"
                        disabled={disableSaveButton}
                        onClick={() => handleSave()}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Dropzone>
        </div>
      </Modal >
    </>
  )
}

export default AddCampaign
