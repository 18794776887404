import { timestampToHour } from "../../../Utils/timestamp";
import ChatsDropdown from "../../Sidebars/Chats/ChatsDropdown";
import { DeletedMessage } from "../Message/MessageItem";
import { Tags } from "../Tags";
import { UnreadCounter } from "../UnreadCounter";
import React, { useEffect } from "react";
import DefaultImage from "../../../assets/img/no-image.png";
import { tagStyle } from "../Tags";
import { convertText } from "../../../Utils/convert-text";

export const ChatListView = (props) => {
  const {
    chat,
    activeChatCustomer,
    chatSelectHandle,
    unreadMessages,
    readOnly,
  } = props;
  const chatActive =
    chat._id === activeChatCustomer._id &&
    chat.channel.id === activeChatCustomer.channel.id;

  const LastMessage = () => {
    if (chat.lastMessage?.deletedAt) {
      return <DeletedMessage />;
    }

    if (chat.lastMessage?.hasMedia) {
      return (
        <small>
          <i className="fa fa-file"></i> Arquivo
        </small>
      );
    }

    if (chat.lastMessage?.type === "vcard") {
      return (
        <small>
          <i className="fa fa-user"></i> Contato
        </small>
      );
    }

    return <small>{convertText(chat.lastMessage?.body)}</small>;
  };

  const handleNewTagsByActiveChatCustomer = () => {
    if(chatActive){
      chat.tags = activeChatCustomer.tags
    }
  }

  useEffect(() => {
    handleNewTagsByActiveChatCustomer()
  }, [activeChatCustomer])

  return (
    <>
      {props.i !== 0 && (
        <hr
          style={{
            width: "90%",
            height: 10,
            margin: "auto",
            borderColor: "lightgray",
          }}
        />
      )}
      <li
        className={"list-group-item " + (chatActive ? "open-chat" : "")}
        style={{ padding: "5px 20px", gap: 5 }}
      >
        <div
          className={`d-flex flex-column ${
            chat.externalId ? "justify-content-around" : ""
          }`}
          onClick={() => chatSelectHandle(chat)}
          style={{ gap: 10 }}
        >
          {chat?.attendance?.transfer ? (
            <i
              className="fa fa-arrow-right bg-info"
              aria-hidden="true"
              style={{
                position: "absolute",
                top: 10,
                left: 15,
                fontSize: 10,
                borderRadius: 10,
                padding: "3px 4px 4px 4px",
              }}
            ></i>
          ) : null}
          <img
            className="rounded-circle"
            style={{ cursor: "pointer", marginRight: 10 }}
            src={chat.image || DefaultImage}
            onError={(e) => {
              e.target.src = DefaultImage;
            }}
            alt={"Imagem usuário " + chat.name}
            width={60}
            height={60}
          />
          {chat?.externalIds
            ? chat.externalIds.map((externalId, i) => (
                <div
                  key={i}
                  className={`bg-dark-bright`}
                  style={{ ...tagStyle, fontSize: 10, padding: "1px 0" }}
                >
                  <span
                    style={{
                      position: "relative",
                      bottom: -1,
                      fontWeight: "bold",
                    }}
                    title={externalId}
                  >
                    Cód.: {externalId}
                  </span>
                </div>
              ))
            : null}
        </div>
        <div
          className="users-list-body"
          style={{ overflow: "initial" }}
        >
          <h5
            onClick={() => chatSelectHandle(chat)}
            style={{ maxWidth: "65%" }}
          >
            {chat?.name}
          </h5>
          <small onClick={() => chatSelectHandle(chat)}>
            {chat?.phoneFormatted}
          </small>
          {!readOnly && (
            <div
              style={{
                overflow: "hidden",
                maxHeight: 20,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              onClick={() => chatSelectHandle(chat)}
            >
              <LastMessage />
            </div>
          )}
          {!readOnly && (
            <span onClick={() => chatSelectHandle(chat)}>
              {chat?.attendance?.departmentName}
            </span>
          )}
          <small
            style={{
              border: "1px solid black",
              borderRadius: 5,
              width: "100%",
              textAlign: "center",
              marginTop: "0.2rem",
            }}
            className="bg-dark-bright"
            onClick={() => chatSelectHandle(chat)}
          >
            {chat?.channel?.name}
          </small>
          <Tags
            tags={chat.tags}
            onClick={() => chatSelectHandle(chat)}
            chat={chat}
          />
          <div
            className="users-list-action action-toggle"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "none",
              boxShadow: "none",
            }}
          >
            {!readOnly && chat.lastMessage && (
              <span
                onClick={() => chatSelectHandle(chat)}
                style={{ fontSize: 12 }}
              >
                {timestampToHour(chat.lastMessage.timestamp)}
              </span>
            )}
            <div style={{ display: "flex", gap: 5 }}>
              {!readOnly && (
                <UnreadCounter
                  customer={chat}
                  activeChatCustomer={activeChatCustomer}
                  unreadMessages={unreadMessages}
                />
              )}
              <ChatsDropdown customer={chat} />
            </div>
          </div>
        </div>
      </li>
    </>
  );
};
