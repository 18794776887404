import React from 'react';
import { Row, Col, Card, CardText, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DefaultImage from "../../../assets/img/no-image.png";

const CardsLayoutServiceReports = ({
    data,
    handleShowProfile,
    handleDropdown,
    findTranslate,
    dateFormatted,
    handleCustomerMessages,
    setOpenModalDetails
    
}) => {

    const toggle = (item) => {
        handleDropdown(item);
    };

    if (data?.length === 0) {
        return (
            <div>
                <i className="fa fa-exclamation-circle" style={{ fontSize: '7em', color: '#ebebeb', marginLeft: '35%' }}></i>
                <p style={{ fontSize: '1.2em', width: '100', textAlign:'center' }}>No momento não há registros.</p>
            </div>
        );
    }
    
    return (
        <div>
            {data.map((item, i) => (
                <Card key={i} className='w-100 p-3 bg-light' style={{
                    borderBottom: '2px solid #6161ff',
                    borderRadius: '10px',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none'
                }}>
                    <Row className='d-flex align-items-start mb-2 bg-primary' style={{ borderRadius: '10px' }}>
                        <Col className='justify-content-start p-2' style={{ display: 'inline-block' }}>
                            <CardText style={{ display: 'flex' }}>
                                <img src={item.customer.image || DefaultImage} alt="Imagem" className="rounded-circle mr-2" style={{ width: 40, height: 40 }} />
                                <strong style={{ fontSize: '18px', width: '110px' }}>{item.customer.name}</strong>
                            </CardText>
                        </Col>
                        <Col className='justify-content-center p-2 text-center ml-auto'>
                            <Dropdown isOpen={item.dropdownOpen} toggle={() => toggle(item)}>
                                <DropdownToggle className='text-light' tag="a">
                                    <i className={'fa fa-ellipsis-v my-3 '}></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => handleCustomerMessages(item)}>
                                        Visualizar conversa
                                    </DropdownItem>
                                    <DropdownItem onClick={() => setOpenModalDetails({ open: true, info: item })}>
                                        Detalhes
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-start mb-2'>
                        <Col className='d-flex justify-content-start p-2' onClick={() => handleShowProfile(item)}>
                            <CardText>
                                <strong style={{ fontSize: '18px' }}>Número</strong><br />
                                {item.customer.cellPhone}
                            </CardText>
                        </Col>
                        <Col className='d-flex justify-content-start p-2' onClick={() => handleShowProfile(item)}>
                            <CardText>
                                <strong style={{ fontSize: '18px' }}>Departamento</strong><br />
                                {item.department.name}
                            </CardText>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-start mb-2'>
                        <Col className='d-flex justify-content-start p-2' onClick={() => handleShowProfile(item)}>
                            <CardText>
                                <strong style={{ fontSize: '18px' }}>Canal</strong><br />
                                {item.channel.name}
                            </CardText>
                        </Col>
                        <Col className='d-flex justify-content-start p-2' onClick={() => handleShowProfile(item)}>
                            <CardText>
                                <strong style={{ fontSize: '18px' }}>Operador</strong><br />
                                {item.operator?.name || ''}
                            </CardText>
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-start mb-2'>
                        <Col className='d-flex justify-content-start p-2' onClick={() => handleShowProfile(item)}>
                            <CardText>
                                <strong style={{ fontSize: '18px' }}>Situação</strong><br />
                                {findTranslate(item.status)}
                            </CardText>
                        </Col>
                        <Col className='d-flex justify-content-start p-2' onClick={() => handleShowProfile(item)}>
                            <CardText>
                                <strong style={{ fontSize: '18px' }}>Hora</strong><br />
                                {dateFormatted(item.startedAt)}
                            </CardText>
                        </Col>
                    </Row>
                </Card>
            ))}
        </div>
    );
};

export default CardsLayoutServiceReports;
