import React, { useState, useEffect, forwardRef } from "react";
import { Modal, ModalBody, Input, Label, CustomInput } from 'reactstrap';
import { getChannelsActives } from '../../Services/Attendance-Service';
import MultiSelect from "../Partials/MultiSelect";
import Select from 'react-select';
import { styleIcon, styleIconType, CustomSingleValue } from "./AddTagModal";
import 'react-tagsinput/react-tagsinput.css'

const SearchModal = forwardRef(({
    modal,
    modalToggle,
    inAttendances,
    handleClearStatesOutModal,
    setListWithAllFilters,
    handleAllFilters
}, ref) => {

    const [channels, setChannels] = useState([])
    const [chosenChannels, setChosenChannels] = useState([])
    const [tags, setTags] = useState([])
    const [choosedTags, setChoosedTags] = useState([])
    const [choosedDepartment, setChoosedDepartment] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [onlyTransferred, setOnlyTransferred] = useState(false)
    const [onlyChannel, setOnlyChannel] = useState(false)

    const changeTransferreds = () => setOnlyTransferred(!onlyTransferred)

    React.useImperativeHandle(ref, () => ({
        handleClearFilters
    }));


    const CustomPlaceholder = (props) => {
        return (
            <div className="multiselect__placeholder css-1jqq78o-placeholder"
                style={{ textAlign: 'start' }} >
                {props.children}
            </div>
        );
    };

    const CustomOption = ({ innerProps, isFocused, data }) => (

        <div {...innerProps}
            style={{
                ...styleIconType,
                backgroundColor: isFocused ? "#e6e6e6" : "",
                textAlign: "left",
                margin: "5px"
            }}
        >
            <span
                style={{
                    ...styleIconType,
                }}>{data.icon}</span>
            {data.label}
        </div>
    );

    const uniqueDepartments = inAttendances
        ? inAttendances.filter((chatClient, index, self) =>
            index === self.findIndex((c) => c.attendance.departmentName === chatClient.attendance.departmentName)
        )
        : [];

    const uniqueTags = inAttendances
        .flatMap(item => item?.tags || [])
        .reduce((acc, tag) => {
            if (tag && !acc.some(existingTag => existingTag?.id === tag?.id)) {
                acc.push(tag);
            }
            return acc;
        }, []);


    const handleChangeChannel = (selected) => {
        setChosenChannels(selected);
    };

    const handleGetChannels = async () => {
        const res = await getChannelsActives()
        setChannels
            (res.map(channel =>
            ({
                value: channel._id,
                label: channel.shortName || channel.name,
            })));

        if (res?.length === 1) {
            setChosenChannels([{
                value: res[0]?._id,
                label: res[0]?.shortName || res[0]?.name
            }])
            setOnlyChannel(true)
        }
    }

    const handleGetOperators = async () => {
        setTags(uniqueTags.map(tag => {
            return {
                value: tag?.id,
                label: tag?.name,
                type: tag?.type,
                icon: <i className={`fa fa-tag text-${tag?.type}`}
                    style={styleIcon}
                />
            }
        }))
    }

    const handleGetDepartments = async () => {
        setDepartments(uniqueDepartments.map(department => {
            return {
                value: department.attendance.id,
                label: department.attendance.departmentName
            }
        }))
    };

    let allFilters
    allFilters = [choosedDepartment, onlyChannel ? [] : chosenChannels, choosedTags, onlyTransferred]

    const filteringWithModal = () => {
        let filteredArray = inAttendances;
        const deparmentNameForFilter = allFilters[0];
        const channelForFilter = allFilters[1];
        const tagsForFilter = allFilters[2];
        const transferred = allFilters[3];

        let count = 0;

        if (Array.isArray(deparmentNameForFilter) && deparmentNameForFilter.length) {
            count += 1;
        }
        if (Array.isArray(channelForFilter) && channelForFilter.length) {
            count += 1;
        }
        if (Array.isArray(tagsForFilter) && tagsForFilter.length) {
            count += 1;
        }
        if (transferred === true) {
            count += 1;
        }

        setListWithAllFilters((prevFilters) => ({
            ...prevFilters,
            departamentName: deparmentNameForFilter,
            channel: channelForFilter,
            tags: tagsForFilter,
            transferred: transferred,
            countFilters: count,
            orderDesc: true

        }))

    };

    const handleClearFilters = () => {
        handleClearStatesOutModal()
        setChannels([])
        setChosenChannels([])
        setChoosedTags([])
        setChoosedDepartment([])
        setDepartments([])
        setOnlyTransferred(false)
        setOnlyChannel(false)

    }

    const HandleGetSearch = () => {
        filteringWithModal()
        modalToggle()
        handleAllFilters()
    }

    useEffect(() => {
        handleGetChannels()
        handleGetOperators()
        handleGetDepartments()
    }, [modal])
    return (
        <Modal zIndex={9999}
            isOpen={modal}
            toggle={modalToggle}
            centered className="call modal-dialog-zoom"
            size='lg'>
            <button className={"btn btn-light btn-modal-close"} onClick={modalToggle}>
                <i className="fa fa-times"></i>
            </button>
            <ModalBody>
                <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
                <div>
                    <h4 style={{ color: 'black' }}>Filtrar contatos</h4>
                    <div className="transfer-info-box">
                        <div style={{ padding: '0 50px', color: 'black', fontWeight: 'bold' }}>
                            <div style={{ gap: 10 }} className="d-flex align-content-center w-100 mt-1">
                                <div className="w-100">
                                    <Label for="operator">Selecione um canal:</Label>
                                    <div style={{ textAlign: 'start' }}>
                                        {
                                            channels.length > 1 ? (
                                                <MultiSelect
                                                    key='channel_id'
                                                    options={channels}
                                                    onChange={(selectedOption) => {
                                                        handleChangeChannel(selectedOption);

                                                    }}
                                                    value={chosenChannels}
                                                    isSelectAll={true}
                                                    menuPlacement='bottom'
                                                    placeholder='Selecione'
                                                    selectAllName='Selecionar todos os canais'
                                                    firstNameSelect='Todos os'
                                                    lastNameSelec='canais selecionados'
                                                    isMulti={false}
                                                    messageForSingular='canal selecionado'
                                                    messageForPlural='canais selecionados'
                                                    components={{
                                                        Placeholder: CustomPlaceholder
                                                    }}
                                                />
                                            ) : (
                                                <Select
                                                    options={channels}
                                                    name='channel_id'
                                                    closeMenuOnSelect={false}
                                                    noOptionsMessage={() => 'Nenhum canal disponível.'}
                                                    placeholder='Selecione'
                                                    classNamePrefix='multiselect'
                                                    menuPortalTarget={document.querySelector('body')}
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                        valueContainer: css => ({
                                                            ...css,
                                                            flexWrap: 'nowrap',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',

                                                        }),
                                                    }}
                                                    isMulti={false}
                                                    onChange={e => {
                                                        setChosenChannels([e]);
                                                    }}
                                                    value={chosenChannels}

                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="w-100">
                                    <Label for="departments">Selecione um departamento:</Label>
                                    <Select
                                        options={departments}
                                        name='departments'
                                        value={choosedDepartment}
                                        closeMenuOnSelect={false}
                                        noOptionsMessage={(e) => 'Nenhum departamento disponível.'}
                                        placeholder="Selecione"
                                        classNamePrefix={'multiselect'}
                                        menuPortalTarget={document.querySelector('body')}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }) }} isMulti={uniqueDepartments?.length > 1}
                                        onChange={e => { setChoosedDepartment(e) }}
                                    />
                                </div>

                            </div>
                            <hr style={{ width: '90%' }} />
                            <Label
                                for="tags"
                                className='mt-2'>Selecione uma tag:</Label>
                            <div className="d-flex" style={{ gap: 10 }}>
                                <div className="w-100">
                                    <Select
                                        options={tags}
                                        name='tags'
                                        closeMenuOnSelect={false}
                                        noOptionsMessage={(e) => 'Nenhuma tag disponível.'}
                                        placeholder={choosedTags ? choosedTags.map((choosedTag) => (choosedTag.label)) : 'Selecione'}
                                        classNamePrefix={'multiselect'}
                                        menuPortalTarget={document.querySelector('body')}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            valueContainer: css => ({
                                                ...css,
                                                flexWrap: "nowrap",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: 'ellipsis',
                                                maxWidth: '30vw'
                                            })
                                        }}
                                        isMulti={true}
                                        isDisabled={tags?.length ? false : true}
                                        onChange={e => setChoosedTags(e)}
                                        components={{
                                            Option: CustomOption,
                                            SingleValue: CustomSingleValue,
                                        }}
                                        value={choosedTags}
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <form>
                                    <div className="custom-control custom-switch pl-2">
                                        <CustomInput
                                            type="switch"
                                            id="quickMessageForAll"
                                            name="quickMessageForAll"
                                            label="Somente transferidos"
                                            checked={onlyTransferred}
                                            onChange={changeTransferreds}
                                        />
                                    </div>
                                </form>
                            </div>
                            <button type="button"
                                onClick={() => { handleClearFilters(); modalToggle() }}
                                className="btn btn-secondary btn-md align-self-end mt-3 mr-1">
                                Cancelar
                            </button>
                            <button type="button"
                                onClick={() => HandleGetSearch()}
                                className="btn btn-primary btn-md align-self-end mt-3 ml-1">
                                Procurar
                            </button>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );

})

export default SearchModal