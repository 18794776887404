import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import api from './Axios.config'

const url = process.env.REACT_APP_API_URL + '/api/contact/v1/customers'

export const postSendMessage = async (phone, message) => {
  try {
    const response = await api.post(`${url}/${phone}/send-messages`, {
      message,
      type: 'text',
    })
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to send message')
  }
}

export const getMessagesHistory = async (phone, page, channelId, dateFrom, dateTo) => {
  try {
    let response

    if (dateTo) {
      response = await api.get(`${url}/${phone}/history-attendances?channelId=${channelId}&order=desc&page=${page}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
    } else {
      response = await api.get(`${url}/${phone}/history-attendances?channelId=${channelId}&order=desc&page=${page}&dateFrom=${dateFrom}`) 
    }
    return { messages: response.data.data, pages: response.data.meta.pages }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get messages history')
  }
}

export const putViewMessages = async (phone) => {
  try {
    const response = await api.put(
      `${process.env.REACT_APP_API_URL}/api/contact/v1/customers/${phone}/view-conversations`
    )
    return response.data
  } catch (error) {
    console.error('Error fetching seeing messages:', error)
    throw new Error('Failed to put seeing messages')
  }
}

export const getContacts = async (page) => {
  try {
    const response = await api.get(`${url}?page=${page}`)
    return response.data
  } catch (error) {
    console.error('Error fetching contacts:', error)
    throw new Error('Failed to get contacts')
  }
}

export const postCustomer = async (body) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/api/contact/v1/customers`, body)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to post customer')
  }
}

export const putCustomer = async (phone, body) => {
  try {
    const response = await api.put(`${process.env.REACT_APP_API_URL}/api/contact/v1/customers/${phone}`, body)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to put customer')
  }
}

export const putWalletCustomer = async (phone, body) => {
  try {
    const response = await api.put(`${process.env.REACT_APP_API_URL}/api/contact/v1/customers/${phone}/wallets`, body)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to put customer')
  }
}

export const postTagToContact = async (body, cellPhone) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/api/contact/v1/customers/${cellPhone}/tags`, body)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Faild to put tag')
  }
}

export const deleteTagToContact = async (channelId, cellPhone, tagId) => {
  try {
    const queryParams = {
      channelId: channelId
    }
    const response = await api.delete(`${process.env.REACT_APP_API_URL}/api/contact/v1/customers/${cellPhone}/tags/${tagId}`, { params: queryParams })
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Faild to put tag')
  }
}